.SELECTED_OHT_container {
    background-color: #E2F0F4;
    width: 100%;
    padding: 25px;
    margin-bottom: 0px !important;

    img {

        margin-bottom: 16px;
        max-width: 30%;

    }

    p {
        margin-bottom: 4px;
    }

    a {

        text-decoration: underline;
        color: #0066CC;
        font-weight: 700;;

    }


}